<template>
  <div class="listform">
    <div class="form-title">经销商家装积分统计
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Button @click="ExportExcel()" type="primary" icon="ios-clipboard">导出</Button>
    </div>

    <div class="datatable">
      <Table :border="false" border size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist" show-summary></Table>
    </div>
  </div>
</template>
<script>
import workerRolSel from "../../components/public_component/workerRowSel";
export default {
  data() {
    return {
      dateSearchValue: "",
      sel_field: "",
      table: {
        tablecolumsTitle: [
          {
            title: "经销商姓名",
            key: "Name",
            align: "center",
            tooltip: true
          },
          {
            title: "目前总积分",
            key: "Integral",
            align: "center",
            tooltip: true
          },
          {
            title: "本月家装积分",
            key: "CompanyIntegral",
            align: "center",
            color: "red",
            tooltip: true,
            render: (h, params) => {
              return h('span',{
                  'style':{
                      'color':'red'
                  }
              },params.row.CompanyIntegral);
            }
          },
          {
            title: "本月家装积分消分",
            key: "OffsetCompanyIntegral",
            align: "center",
            color: "red",
            tooltip: true,
            render: (h, params) => {
              return h('span',{
                  'style':{
                      'color':'red'
                  }
              },params.row.OffsetCompanyIntegral);
            }
          },
        ],
        datalist: [],
      }
    };
  },
  components: {
    "worker-RowSel": workerRolSel
  },
  methods: {
    //行选中事件
    WorkerRowSel: function(e) {
      if (this.IsEditWorker) {
        this.EditWorker(undefined, e);
        return;
      }
      this.PreSelWorker(undefined, e);
    },
    ModalCancel: function(e) {
      console.log("取消");
      this.$http.SetConditions();
    },
    DateTimeSelect: function(e) {
      this.dateSearchValue = e;
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Dealer/GetDealerCompanyIntegral", {
          params: {}
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.Data;
        });
    },
    ExportExcel:function () {//导出
      var that=this;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      that.$http.get("Dealer/ExportDealerCompanyIntegral",{
        params:{}
      }).then(res=>{
        that.$Spin.hide();
        window.open(that.$config.fileUrl + res.data);
      })
    },
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
.radio-group {
  margin: 10px;
  padding-left: 10px;
}
.datatable {
  margin-top: 5px;
}
.ivu-radio-group-button .ivu-radio-wrapper-checked {
  background: #2d8cf0;
  color: #f8f8f8;
}
</style>
 